<template>
  <div class="home">
    <div class="main">
      <div class="header">
        <div class="title-logo">
          <!-- <img src="../assets/index/BX-yellow.png" alt="" /> -->
          <img style="width:25vw" src="../assets/index/logo.png" alt="">
        </div>
        <div style="display: flex;">
          <div
            v-for="(item, i) in menuList"
            :key="i"
            class="header-right"
            @click="routerLink(i, item.path)"
          >
            <div
              :class="$store.state.isShallow ? 'itemblock' : 'deep-itemblock'"
            >
              <span
                :class="
                  $store.state.PageIndex == i
                    ? $store.state.isShallow
                      ? ' circle bg'
                      : ' circle deepbg'
                    : 'circle'
                "
              ></span>
              <span
                :class="
                  $store.state.PageIndex == i
                    ? $store.state.isShallow
                      ? ' title color'
                      : ' title deep'
                    : 'title'
                "
                >{{ item.name }}</span
              >
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="view">
      <transition
        name="change"
        mode="out-in"
        enter-active-class="animate__animated animate__fadeInRight"
      >
        <!-- <keep-alive> -->
          <router-view></router-view>
        <!-- </keep-alive> -->
      </transition>
    </div>
    <div class="footer">
      <div class="mains">
        <div class="logoAddress">
          <div class="logo">
            <img src="../assets/index/BX-yellow.png" alt="" />
            <span>浙江白象成套设备有限公司</span>
          </div>
          <div class="zixun">咨询热线</div>
          <div class="text">
            公司地址：浙江省杭州市余杭区五常街道西坝路131号B座216
          </div>
          <div class="text">
            生产基地：浙江省湖州市德清县乾元镇苕溪东街1135号
          </div>
          <div class="text">联系电话：18758021092，13372507779</div>
        </div>
        <div class="block">
          <div class="zixun">热门产品</div>
          <div class="text" v-for="(item,i) in hotProduct" :key="i">{{ item.name }}</div>
        </div>
        <div class="block">
          <div class="zixun">快速链接</div>
          <div class="text">
            <a  target="_blank" href="http://zijingshuke.com">字景物联网平台</a> 
          </div>
          <div class="text">
            <a  target="_blank" href="http://cloud.zjbaixiang.cn:9898/baixiangyun/#/">白象云管理系统</a>
          </div>
        </div>
      </div>
      <div class="brand">
      @浙江白象成套设备有限公司 版权所有 
      <a href="https://beian.miit.gov.cn" target="_blank" class="ICP"
          > 浙ICP备18016400号-2</a
        >
      </div>
    </div>
  </div>
</template>
<script>
// import axios from 'axios';

export default {
  data() {
    return {
      menuList: [
        { name: "首页", index: 0, path: "/index" },
        { name: "产品展示厅", index: 1, path: "/hall" },
        { name: "技术支持与服务", index: 2, path: "/support" },
        { name: "客户项目展示", index: 3, path: "/case" },
        { name: "公司新闻与董事长寄语", index: 4, path: "/news" },
        { name: "联系我们", index: 5, path: "/contact" },
      ],
      pageIndex:0,  
      //热门产品
      hotProduct:[
        {name:'一体化控制柜(箱)'},
        {name:'一体化前端控制模块'},
        {name:'单路电机控制箱'},
        {name:'一用一备水泵控制箱'},
      ]
    };
  },
  created() {
    // console.log(sessionStorage.getItem('PageIndex'))
    if(sessionStorage.getItem('PageIndex')==null){
      this.$store.commit("changeIndex", 0);
    }
  },
  methods: {
    // 跳转路由
    routerLink(i, path) {
      //   this.$store.state.PageIndex = i;
      sessionStorage.setItem('PageIndex',i)
      const index = sessionStorage.getItem('PageIndex')
      // console.log(index)
      this.$store.commit("changeIndex", index);
      // if (i == 3) {
      //   this.$store.commit("changeTheme", false);
      // } else {
      //   this.$store.commit("changeTheme", true);
      // }
      this.$router.push(path);
    },
  },
};
</script>
<style lang="scss" scoped>
.home {
  position: relative;
  font-size: 16px;
  .main {
    height: 1.1rem;
    box-shadow: 0px 2px 6px 0px rgba(219, 219, 219, 0.5);
    margin: 0;
    padding: 0.2rem 0.5rem;
    box-sizing: border-box;
    position: fixed;
    top: 0;
    background: #fff;
    width: 100%;
    z-index: 999;
    // position: relative
  }
  .shadow {
    position: absolute;
    z-index: 99;
    width: 100%;
    height: 1.2rem;
    background: linear-gradient(
      180deg,
      rgba(14, 14, 14, 0.95) 1%,
      rgba(36, 36, 36, 0) 100%
    );
  }

  .title-logo {
    img {
      height: 0.7rem;
    }
  }

  .header {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    .header-right {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      margin-right: 20px;
      cursor: pointer;
    }

    .circle {
      display: inline-block;
      width: 6px;
      height: 6px;
      border-radius: 50%;
      //   background-color: #909CBD;
      background-color: #a0a0a0;
      vertical-align: middle;
      // margin-top: 14px;
    }

    .title {
      color: rgba(255, 255, 255, 1);
      font-size: 1.34vw;
      font-family: PingFangSC-Semibold;
      text-align: left;
      white-space: nowrap;
      line-height: 28px;
      margin: 0 0 0 10px;
      vertical-align: middle;
      color: #a0a0a0;
    }

    .color {
      color: #565656;
      font-weight: 600;
    }

    .bg {
      background-color: #565656;
    }

    .deep {
      color: #565656;
      font-weight: 600;
    }
    .deepbg {
      background-color: #565656;
    }
    .itemblock,.deep-itemblock{
      display: flex;
      align-items: center;
      flex-wrap: nowrap;
    }
    .itemblock:hover {
      .title {
        color: #565656;
      }

      .circle {
        background-color: #565656;
      }
    }

    .deep-itemblock:hover {
      .title {
        color: #565656;
      }

      .circle {
        background-color: #565656;
      }
    }
  }

  .footer {
    width: 100%;
    background: #0d0f1b;
    color: #ffffff;
    .mains {
      width: 80%;
      margin: 0 10%;
      height: 200px;
      border-bottom: solid 1px #92959c;
      display: flex;
      justify-content: space-between;
      padding: 40px 0 20px;
      .logo {
        font-size: 1.5vw;
        font-family: Arial-Black, Arial;
        font-weight: 900;
        cursor: pointer;
        img{
          width: 5vw;
          vertical-align: middle;
        }
        span{
          vertical-align: middle;
          margin-left: 0.5vw;
        }
      }
      .zixun {
        font-size: 0.95vw;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        margin: 10px 0;
        cursor: pointer;
      }
      .text {
        font-size: 0.85vw;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #a4a4ae;
        margin: 10px 0;
        cursor: pointer;
        a{
          text-decoration: none;
          color: #a4a4ae;
        }
      }
      .text:hover{
        font-size: 0.95vw;
        color: white;
        a{
          color: #fff;
        }
      }
    }
    .brand {
      text-align: center;
      padding: 20px 0;
      font-size: 0.93vw;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #a4a4ae;
      cursor: pointer;
      a{
        color: #a4a4ae;
        text-decoration: none;
      }
      a:hover{
        font-size: 0.95vw;
        color: #fff;
      }
    }
  }
}
</style>

