var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"home"},[_c('div',{staticClass:"main"},[_c('div',{staticClass:"header"},[_vm._m(0),_c('div',{staticStyle:{"display":"flex"}},_vm._l((_vm.menuList),function(item,i){return _c('div',{key:i,staticClass:"header-right",on:{"click":function($event){return _vm.routerLink(i, item.path)}}},[_c('div',{class:_vm.$store.state.isShallow ? 'itemblock' : 'deep-itemblock'},[_c('span',{class:_vm.$store.state.PageIndex == i
                  ? _vm.$store.state.isShallow
                    ? ' circle bg'
                    : ' circle deepbg'
                  : 'circle'}),_c('span',{class:_vm.$store.state.PageIndex == i
                  ? _vm.$store.state.isShallow
                    ? ' title color'
                    : ' title deep'
                  : 'title'},[_vm._v(_vm._s(item.name))])])])}),0)])]),_c('div',{staticClass:"view"},[_c('transition',{attrs:{"name":"change","mode":"out-in","enter-active-class":"animate__animated animate__fadeInRight"}},[_c('router-view')],1)],1),_c('div',{staticClass:"footer"},[_c('div',{staticClass:"mains"},[_vm._m(1),_c('div',{staticClass:"block"},[_c('div',{staticClass:"zixun"},[_vm._v("热门产品")]),_vm._l((_vm.hotProduct),function(item,i){return _c('div',{key:i,staticClass:"text"},[_vm._v(_vm._s(item.name))])})],2),_vm._m(2)]),_vm._m(3)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"title-logo"},[_c('img',{staticStyle:{"width":"25vw"},attrs:{"src":require("../assets/index/logo.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"logoAddress"},[_c('div',{staticClass:"logo"},[_c('img',{attrs:{"src":require("../assets/index/BX-yellow.png"),"alt":""}}),_c('span',[_vm._v("浙江白象成套设备有限公司")])]),_c('div',{staticClass:"zixun"},[_vm._v("咨询热线")]),_c('div',{staticClass:"text"},[_vm._v(" 公司地址：浙江省杭州市余杭区五常街道西坝路131号B座216 ")]),_c('div',{staticClass:"text"},[_vm._v(" 生产基地：浙江省湖州市德清县乾元镇苕溪东街1135号 ")]),_c('div',{staticClass:"text"},[_vm._v("联系电话：18758021092，13372507779")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"block"},[_c('div',{staticClass:"zixun"},[_vm._v("快速链接")]),_c('div',{staticClass:"text"},[_c('a',{attrs:{"target":"_blank","href":"http://zijingshuke.com"}},[_vm._v("字景物联网平台")])]),_c('div',{staticClass:"text"},[_c('a',{attrs:{"target":"_blank","href":"http://cloud.zjbaixiang.cn:9898/baixiangyun/#/"}},[_vm._v("白象云管理系统")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"brand"},[_vm._v(" @浙江白象成套设备有限公司 版权所有 "),_c('a',{staticClass:"ICP",attrs:{"href":"https://beian.miit.gov.cn","target":"_blank"}},[_vm._v(" 浙ICP备18016400号-2")])])
}]

export { render, staticRenderFns }